<template>
	<div id="mian">
		<div class="desc">
			<h5 class="jsxx">基本信息</h5>
			<el-button @click="toUrl('third_party_edit')" class="editBt" type="primary" v-auth="'ACCOUNT:WXAPP:THIRDS/DETAIL/EDIT'">编辑</el-button>
		</div>
		<div class="list clear">
			<ul class="listBox">
				<li>
					<span>第三方名称</span>
          <i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.thirdName" placement="top">
              <span>{{detail.thirdName}}</span>
            </el-tooltip>
          </i>
				</li>
				<li>
					<span>第三方编号</span>
					<i class="hidden1">{{detail.thirdNo}}</i>
				</li>
				<li>
					<span>第三方APPID</span>
					<i class="hidden1">{{detail.appId}}</i>
				</li>
				<li>
					<span>第三方联系号码</span>
					<i class="hidden1">{{detail.componentPhone}}</i>
				</li>
				<li>
					<span>服务器域名</span>
					<i class="hidden1">{{detail.requestDomain}}</i>
				</li>
				<li>
					<span>加解密key</span>
					<i class="hidden1">{{detail.encodingAesKey}}</i>
				</li>
				<li>
					<span>验证token</span>
					<i class="hidden1">{{detail.token}}</i>
				</li>
				<li>
					<span>密钥</span>
					<i class="hidden1">{{detail.appSecret}}</i>
				</li>
				<li>
					<span>第三方redirect_url</span>
          <i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.thirdRedirectUrl" placement="top">
              <span>{{detail.thirdRedirectUrl}}</span>
            </el-tooltip>
          </i>
				</li>
				<li>
					<span>备注</span>
          <i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.remark" placement="top">
              <span>{{detail.remark}}</span>
            </el-tooltip>
          </i>
				</li>
				<li>
					<span>授权url</span>
          <i class="hidden1"  style="height:auto;"> <!--class="hidden1"-->
            <!-- <el-tooltip class="item" effect="light" :content="warrantUrl" placement="top"> -->
						 <a @click="getThirdsUrlBtn(detail.appId,detail.appSecret)" v-show="warrantUrlShow==false">生成授权url</a>
             <a :href="warrantUrl" target="blank" style="word-wrap: break-word;" v-show="warrantUrlShow==true"> <span>{{warrantUrl}}</span></a>
            <!-- </el-tooltip> -->
          </i>
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
	import {
		getThirds,getThirdsUrl
	} from '@/api/user/applet.js'
	export default {
		data() {
			return {
				detail:'',
				warrantUrl:"",
				warrantUrlShow:false,
			};
		},
		created() {
			this.getDetail();
			console.log(this.$route.query.warrantUrl,"this.$route.query.warrantUrl")
			this.warrantUrl = this.$route.query.warrantUrl
		},
		methods: {
			//获取详情
			getDetail() {
				getThirds(this.$route.query.id).then(res => {
					if (res) {
						this.detail = res.resultData;
					}
				})
			},
			//获取授权url
			getThirdsUrlBtn(appId,appSecret){
				getThirdsUrl(appId,appSecret).then((res) => {
					if (res) {
						this.warrantUrl = res.resultData.preUrl;
						this.warrantUrlShow = true;
					}
				});
			},
			// 跳转页面
			toUrl(name) {
				this.$router.push({
					name: name,
					query: {
						pageType: 'edit',
						id: this.$route.query.id,
						// warrantUrl:this.warrantUrl,
					}
				})
			},
		}
	}
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/detail.css";


</style>
